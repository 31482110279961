import { Customer } from '../customer.model';
import { PlanFeature, PlanFeatures } from '../plan.model';

export const canAddCustomProperty = (
  plan: PlanFeatures,
  customer: Customer,
): boolean => {
  if (plan[PlanFeature.CUSTOM_USER_PROPERTIES] === -1) {
    return true;
  }

  if (!customer.numberOfCustomProperties) {
    return true;
  }

  return (
    plan[PlanFeature.CUSTOM_USER_PROPERTIES] > customer.numberOfCustomProperties
  );
};
