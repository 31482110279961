/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Customer } from './customer.model';
import { canAddCustomProperty } from './plan-feature-check/can-add-custom-property';

/**
 * When a feature has a value of -1, it means that the feature is unlimited.
 */
export const FEATURE_UNLIMITED = -1;

export const ADD_ON_USERS_COUNT = 2500;

export enum PlanFeature {
  APP = 'app',
  ADMINS = 'admins',
  VIMEOUPLOAD = 'vimeoupload',
  COURSES = 'courses',
  STORAGE = 'storage',
  CHILDAREAS = 'childareas',
  CUSTOM_DOMAIN = 'customDomain',
  NO_BRANDING = 'no-branding',
  FINGERPRINT = 'fingerprint',
  CSV_IMPORT = 'csvImport',
  CUSTOM_ROLES = 'customeRoles',
  SURVEYS = 'surveys',
  INDIV_DASHBOARDS = 'individual-dashboards',
  CUSTOM_USER_PROPERTIES = 'custom-user-properties',
  ORGANISATIONS = 'organisations',
  AI_COURSE_EDITOR = 'ai-course-editor',
}

export const PlanFeatureChecker: Record<
  PlanFeature,
  (plan: PlanFeatures, customer: Customer) => boolean
> = {
  [PlanFeature.ADMINS]: (plan: PlanFeatures, customer: Customer) =>
    plan[PlanFeature.ADMINS] > 0
      ? (customer?.numberOfAdmins || 0) <
        plan[PlanFeature.ADMINS] +
          (customer?.additionalAdmins || 0) +
          (customer?.addOns?.admins || 0) +
          (customer?.addOns?.areas || 0) * 3
      : true,

  [PlanFeature.CHILDAREAS]: (plan: PlanFeatures, customer: Customer) =>
    plan[PlanFeature.CHILDAREAS] >= 0
      ? (customer?.numberOfChildAreas || 0) <
        plan[PlanFeature.CHILDAREAS] +
          (customer?.additionalAreas || 0) +
          (customer?.addOns?.areas || 0)
      : true,

  [PlanFeature.COURSES]: (plan: PlanFeatures, customer: Customer) =>
    plan[PlanFeature.COURSES] > 0
      ? (customer?.numberOfCourses || 0) <
        plan[PlanFeature.COURSES] +
          (customer?.additionalCourses || 0) +
          (customer?.addOns?.areas || 0) * 10
      : true,

  [PlanFeature.APP]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.APP],

  [PlanFeature.FINGERPRINT]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.FINGERPRINT],

  [PlanFeature.VIMEOUPLOAD]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.VIMEOUPLOAD],

  [PlanFeature.CUSTOM_DOMAIN]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.CUSTOM_DOMAIN],

  [PlanFeature.STORAGE]: (plan: PlanFeatures, customer: Customer) =>
    plan[PlanFeature.STORAGE] > 0
      ? (customer?.storageSum || 0) < plan[PlanFeature.STORAGE]
      : true,

  [PlanFeature.NO_BRANDING]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.NO_BRANDING],

  [PlanFeature.CSV_IMPORT]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.CSV_IMPORT],
  [PlanFeature.CUSTOM_ROLES]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.CUSTOM_ROLES],
  [PlanFeature.SURVEYS]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.SURVEYS],
  [PlanFeature.INDIV_DASHBOARDS]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.INDIV_DASHBOARDS],
  [PlanFeature.CUSTOM_USER_PROPERTIES]: canAddCustomProperty,
  [PlanFeature.ORGANISATIONS]: (plan: PlanFeatures, _customer: Customer) =>
    plan[PlanFeature.ORGANISATIONS],
  [PlanFeature.AI_COURSE_EDITOR]: (plan: PlanFeatures) =>
    plan[PlanFeature.AI_COURSE_EDITOR],
};

export const checkFeature = (customer: Customer, feature: PlanFeature) => {
  const plan = PlanMap[customer.plan];
  const featureCheckerFct = PlanFeatureChecker[feature];

  return featureCheckerFct(plan, customer);
};

export interface PlanFeatures {
  [PlanFeature.ADMINS]: number;
  [PlanFeature.STORAGE]: number;
  students: number;

  [PlanFeature.COURSES]: number;
  [PlanFeature.CHILDAREAS]: number;

  [PlanFeature.APP]: boolean;
  [PlanFeature.FINGERPRINT]: boolean;
  [PlanFeature.VIMEOUPLOAD]: boolean;
  [PlanFeature.CUSTOM_DOMAIN]: boolean;
  [PlanFeature.NO_BRANDING]: boolean;
  [PlanFeature.CSV_IMPORT]: boolean;
  [PlanFeature.CUSTOM_ROLES]: boolean;
  [PlanFeature.SURVEYS]: boolean;
  [PlanFeature.INDIV_DASHBOARDS]: boolean;
  [PlanFeature.CUSTOM_USER_PROPERTIES]: number;
  [PlanFeature.ORGANISATIONS]: boolean;
  [PlanFeature.AI_COURSE_EDITOR]: boolean;
}

export const TrialPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 5,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 2500,

  [PlanFeature.COURSES]: 10,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: false,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: false,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 3,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const EssentialPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 1,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 500,

  [PlanFeature.COURSES]: 1,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: false,
  [PlanFeature.FINGERPRINT]: false,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: false,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: false,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 3,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const EssentialPlusPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 2,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 500,

  [PlanFeature.COURSES]: 2,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: false,
  [PlanFeature.FINGERPRINT]: false,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: false,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: false,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 3,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const ProPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 3,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 10000,

  [PlanFeature.COURSES]: 10,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: false,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 5,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const ProV2Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 3,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 5000,

  [PlanFeature.COURSES]: 10,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: false,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 5,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const ProV3Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 3,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 2500,

  [PlanFeature.COURSES]: 10,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: false,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 5,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const Pro5Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 3,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 10000,

  [PlanFeature.COURSES]: 10,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: false,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 5,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const ProUnlimitedPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 5,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: FEATURE_UNLIMITED,

  [PlanFeature.COURSES]: FEATURE_UNLIMITED,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: false,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: false,
  [PlanFeature.SURVEYS]: false,
  [PlanFeature.INDIV_DASHBOARDS]: false,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 5,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const ProPlusPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 10,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 10000,

  [PlanFeature.COURSES]: 20,
  [PlanFeature.CHILDAREAS]: 1,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 5,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const ProLim: PlanFeatures = {
  [PlanFeature.ADMINS]: 10,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 10000,

  [PlanFeature.COURSES]: 20,
  [PlanFeature.CHILDAREAS]: 1,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: 5,
  [PlanFeature.ORGANISATIONS]: false,
  [PlanFeature.AI_COURSE_EDITOR]: false,
};

export const BusinessPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 15,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 10000,

  [PlanFeature.COURSES]: 30,
  [PlanFeature.CHILDAREAS]: 2,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const BusinessV2Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 10,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 10000,

  [PlanFeature.COURSES]: 20,
  [PlanFeature.CHILDAREAS]: 1,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const BusinessV3Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 30,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 5000,

  [PlanFeature.COURSES]: 50,
  [PlanFeature.CHILDAREAS]: 1,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const BusinessV4Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 30,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 5000,

  [PlanFeature.COURSES]: 50,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const BusinessUnlimitedPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 15,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: FEATURE_UNLIMITED,

  [PlanFeature.COURSES]: FEATURE_UNLIMITED,
  [PlanFeature.CHILDAREAS]: 2,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const BusinessPlusPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 30,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 15000,

  [PlanFeature.COURSES]: 50,
  [PlanFeature.CHILDAREAS]: 4,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const PremiumPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 100,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 150000,

  [PlanFeature.COURSES]: 100,
  [PlanFeature.CHILDAREAS]: 9,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const PremiumV2Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 50,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 30000,

  [PlanFeature.COURSES]: 50,
  [PlanFeature.CHILDAREAS]: 2,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const PremiumV3Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 100,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 15000,

  [PlanFeature.COURSES]: FEATURE_UNLIMITED,
  [PlanFeature.CHILDAREAS]: 2,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const PremiumV4Plan: PlanFeatures = {
  [PlanFeature.ADMINS]: 100,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 15000,

  [PlanFeature.COURSES]: FEATURE_UNLIMITED,
  [PlanFeature.CHILDAREAS]: 0,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const PremiumPlusPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: 100,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: 50000,

  [PlanFeature.COURSES]: 100,
  [PlanFeature.CHILDAREAS]: 4,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export const EnterprisePerUserPlan: PlanFeatures = {
  [PlanFeature.ADMINS]: FEATURE_UNLIMITED,
  [PlanFeature.STORAGE]: FEATURE_UNLIMITED,
  students: FEATURE_UNLIMITED,

  [PlanFeature.COURSES]: FEATURE_UNLIMITED,
  [PlanFeature.CHILDAREAS]: 40,

  [PlanFeature.APP]: true,
  [PlanFeature.FINGERPRINT]: true,
  [PlanFeature.VIMEOUPLOAD]: true,
  [PlanFeature.CUSTOM_DOMAIN]: true,
  [PlanFeature.NO_BRANDING]: true,
  [PlanFeature.CSV_IMPORT]: true,
  [PlanFeature.CUSTOM_ROLES]: true,
  [PlanFeature.SURVEYS]: true,
  [PlanFeature.INDIV_DASHBOARDS]: true,
  [PlanFeature.CUSTOM_USER_PROPERTIES]: FEATURE_UNLIMITED,
  [PlanFeature.ORGANISATIONS]: true,
  [PlanFeature.AI_COURSE_EDITOR]: true,
};

export enum Plan {
  TRIAL = 'trial',
  ESSENTIAL = 'essential',
  ESSENTIAL_YEARLY = 'essential-yearly',
  ESSENTIAL_V2_YEARLY = 'essential-v2-yearly',
  ESSENTIAL_PLUS = 'essential-plus',
  ESSENTIAL_PLUS_YEARLY = 'essential-plus-yearly',
  PRO = 'pro',
  PRO_YEARLY = 'pro-yearly',
  PRO_V2 = 'pro-v2',
  PRO_YEARLY_V2 = 'pro-v2-yearly',
  PRO_V3 = 'pro-v3',
  PRO_V3_YEARLY = 'pro-v3-yearly',
  PRO_5 = 'pro5',
  PRO_5_YEARLY = 'pro5-yearly',
  PRO_UNLIMITED = 'pro-unlimited',
  PRO_UNLIMITED_YEARLY = 'pro-unlimited-yearly',
  PRO_PLUS = 'pro-plus',
  PRO_PLUS_YEARLY = 'pro-plus-yearly',
  PRO_LIM = 'pro-lim',
  PRO_LIM_YEARLY = 'pro-lim-yearly',
  BUSINESS = 'business',
  BUSINESS_YEARLY = 'business-yearly',
  BUSINESS_V2 = 'business-v2',
  BUSINESS_V2_YEARLY = 'business-v2-yearly',
  BUSINESS_V3 = 'business-v3',
  BUSINESS_V3_YEARLY = 'business-v3-yearly',
  BUSINESS_V4 = 'business-v4',
  BUSINESS_V4_YEARLY = 'business-v4-yearly',
  BUSINESS_UNLIMITED = 'business-unlimited',
  BUSINESS_UNLIMITED_YEARLY = 'business-unlimited-yearly',
  BUSINESS_PLUS = 'business-plus',
  BUSINESS_PLUS_YEARLY = 'business-plus-yearly',
  PREMIUM = 'premium',
  PREMIUM_YEARLY = 'premium-yearly',
  PREMIUM_V2 = 'premium-v2',
  PREMIUM_V2_YEARLY = 'premium-v2-yearly',
  PREMIUM_V3 = 'premium-v3',
  PREMIUM_V3_YEARLY = 'premium-v3-yearly',
  PREMIUM_V4 = 'premium-v4',
  PREMIUM_V4_YEARLY = 'premium-v4-yearly',
  PREMIUM_PLUS = 'enterprise',
  PREMIUM_PLUS_YEARLY = 'enterprise-yearly',
  ENTERPRISE_PER_USER = 'enterprise-per-user',
  ENTERPRISE_PER_ACTIVE_USER = 'enterprise-per-active-user',
  ENTERPRISE_PER_LEARNING_USER = 'enterprise-per-learning-user',
}

type PlanMapType = {
  [key in Plan]: PlanFeatures;
};

export const PlanMap: PlanMapType = {
  [Plan.TRIAL]: TrialPlan,
  [Plan.ESSENTIAL]: EssentialPlan,
  [Plan.ESSENTIAL_YEARLY]: EssentialPlan,
  [Plan.ESSENTIAL_V2_YEARLY]: EssentialPlan,
  [Plan.ESSENTIAL_PLUS]: EssentialPlusPlan,
  [Plan.ESSENTIAL_PLUS_YEARLY]: EssentialPlusPlan,
  [Plan.PRO]: ProPlan,
  [Plan.PRO_YEARLY]: ProPlan,
  [Plan.PRO_V2]: ProV2Plan,
  [Plan.PRO_YEARLY_V2]: ProV2Plan,
  [Plan.PRO_V3]: ProV3Plan,
  [Plan.PRO_V3_YEARLY]: ProV3Plan,
  [Plan.PRO_5]: Pro5Plan,
  [Plan.PRO_5_YEARLY]: Pro5Plan,
  [Plan.PRO_UNLIMITED]: ProUnlimitedPlan,
  [Plan.PRO_UNLIMITED_YEARLY]: ProUnlimitedPlan,
  [Plan.PRO_PLUS]: ProPlusPlan,
  [Plan.PRO_PLUS_YEARLY]: ProPlusPlan,
  [Plan.PRO_LIM]: ProLim,
  [Plan.PRO_LIM_YEARLY]: ProLim,
  [Plan.BUSINESS]: BusinessPlan,
  [Plan.BUSINESS_YEARLY]: BusinessPlan,
  [Plan.BUSINESS_V2]: BusinessV2Plan,
  [Plan.BUSINESS_V2_YEARLY]: BusinessV2Plan,
  [Plan.BUSINESS_V3]: BusinessV3Plan,
  [Plan.BUSINESS_V3_YEARLY]: BusinessV3Plan,
  [Plan.BUSINESS_V4]: BusinessV4Plan,
  [Plan.BUSINESS_V4_YEARLY]: BusinessV4Plan,
  [Plan.BUSINESS_UNLIMITED]: BusinessUnlimitedPlan,
  [Plan.BUSINESS_UNLIMITED_YEARLY]: BusinessUnlimitedPlan,
  [Plan.BUSINESS_PLUS]: BusinessPlusPlan,
  [Plan.BUSINESS_PLUS_YEARLY]: BusinessPlusPlan,
  [Plan.PREMIUM]: PremiumPlan,
  [Plan.PREMIUM_YEARLY]: PremiumPlan,
  [Plan.PREMIUM_V2]: PremiumV2Plan,
  [Plan.PREMIUM_V2_YEARLY]: PremiumV2Plan,
  [Plan.PREMIUM_V3]: PremiumV3Plan,
  [Plan.PREMIUM_V3_YEARLY]: PremiumV3Plan,
  [Plan.PREMIUM_V4]: PremiumV4Plan,
  [Plan.PREMIUM_V4_YEARLY]: PremiumV4Plan,
  [Plan.PREMIUM_PLUS]: PremiumPlusPlan,
  [Plan.PREMIUM_PLUS_YEARLY]: PremiumPlusPlan,
  [Plan.ENTERPRISE_PER_USER]: EnterprisePerUserPlan,
  [Plan.ENTERPRISE_PER_ACTIVE_USER]: EnterprisePerUserPlan,
  [Plan.ENTERPRISE_PER_LEARNING_USER]: EnterprisePerUserPlan,
};
