import { FirestoreTimestamp } from '../types/firestore/timestamp.firestore.type';
import { Affiliate } from './affiliate.model';
import { Plan } from './plan.model';

export interface Customer extends CustomerStats {
  id: string; // equal to schoolId;
  name: string; // equal to schoolName;
  isActive: boolean;

  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  stripePlanId?: string;
  stripePlanSubItemId?: string;
  refLink?: string;

  bookingInProgress?: boolean;

  useHddStorage?: boolean;

  enterpriseTrial?: boolean;

  plan: Plan;
  status: SubscriptionStatus;
  lastPaymentErrorCode?: string | null;
  requiresAction?: boolean;
  openInvoices?: OpenInvoice[];

  /**
   * Stripe cancellation reasons
   * More Info: https://stripe.com/docs/api/subscriptions/object#subscription_object-cancellation_details-reason
   */
  cancellationReason?: CancellationReason | null;

  currentPeriodStart?: FirestoreTimestamp;
  currentPeriodEnd?: FirestoreTimestamp;
  canceled?: boolean;
  cancelAt?: FirestoreTimestamp | null;

  addOns?: AddOns;

  startDate: FirestoreTimestamp;
  email: string | null;

  billingAddress?: BillingAddress;
  taxExempt?: TaxExempt;

  special?: boolean;
  disabled?: FirestoreTimestamp | null;
  preventDeletion: boolean;
  deletionAlert?: DELETION_ALERT_STEP | null;

  trailing?: boolean;
  remainingTrailDays?: number;
  locked?: boolean;

  coupon?: Coupon;

  affiliateFrom?: Pick<Affiliate, 'id' | 'name'>;

  isChildOf?: string;
  connectedTo?: string[];

  csvImportEnabled?: boolean;

  children?: Children;

  hasApp?: boolean;
  hasMoodtracker?: boolean;
}

export enum DELETION_ALERT_STEP {
  THREE_MONTHS = '3M',
  ONE_MONTH = '1M',
  ONE_WEEK = '1W',
  ONE_DAY = '1D',
}
export interface CustomerStats {
  numberOfCourses?: number;
  numberOfCoursesThisSchool?: number;
  numberOfChildAreas?: number;
  numberOfAdmins?: number;

  /**
   * Number of active members in the school
   * Used for per user plans and billing
   */
  numberOfActiveMembers?: number;
  numberOfCustomProperties?: number;

  additionalCourses?: number;
  additionalAdmins?: number;
  additionalAreas?: number;

  storage?: CustomerStorage;
  storageSum?: number;
  numberOfSpaces?: number;
  numberOfModerators?: number;

  /**
   * Total Number of users in the school
   */
  schoolUsersCount?: number;

  /**
   *
   * Total number of users in this and all connected schools
   * */
  schoolUsersInAllConnectedSchools?: number;
}

export enum CancellationReason {
  CANCELLATION_REQUESTED = 'cancellation_requested',
  PAYMENT_DISPUTED = 'payment_disputed',
  PAYMENT_FAILED = 'payment_failed',
}

export enum TaxExempt {
  NONE = 'none',
  EXEMPT = 'exempt',
  REVERSE = 'reverse',
  PENDING = 'pending',
}

export interface Children {
  [key: string]: {
    numberOfCourses?: number;
  } | null;
}

export interface OpenInvoice {
  id: string;
  number: string;
  dueDate: FirestoreTimestamp | null;
}

export interface AddOns {
  highQualityOption?: boolean;
  enterpriseApi?: boolean;
  spaces?: number;
  moderators?: number;
  courses?: number;
  admins?: number;
  areas?: number;
  schoolUsers?: number;
  customMailSender?: boolean;
}

export interface Coupon {
  duration: string;
  durationInMonth: number | null;
  percentOff: number;
  amountOff: number;
  name: string;
  id: string;
}

export interface CustomerStorage {
  files?: number;
  audios?: number;
  thumbnails?: number;
  videos?: number;
}

export interface BillingAddress {
  name: string;
  street: string;
  street2: string;
  postalCode: string;
  city: string;
  country: string;
  email: string;
  vatId?: string | null;
  phone?: string;
}

export interface StripeUpdateAddressData {
  schoolId: string;
  address: BillingAddress;
}

export interface StripeAddCouponData {
  schoolId: string;
  couponId: string;
}

export const hasOpenInvoices = (customer: Customer): boolean =>
  !!customer.openInvoices?.length;

export const hasOverdueInvoices = (customer: Customer): boolean =>
  !!customer.openInvoices?.filter(
    (inv) => inv.dueDate === null || inv.dueDate?.toDate() < new Date(),
  )?.length;

/**
 * Stripe subscription states
 * More Info: https://stripe.com/docs/billing/lifecycle
 * https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses
 */
export enum SubscriptionStatus {
  TRIALING = 'trialing',
  ACTIVE = 'active',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

export const showTrialAddMemberNotice = (customer?: Customer): boolean => {
  if (!customer) {
    return true;
  }

  if (customer?.csvImportEnabled) {
    return false;
  }

  if (customer?.plan === Plan.TRIAL) {
    return false;
  }

  return customer?.status !== SubscriptionStatus.ACTIVE;
};
